import React from "react";
import { useSelector } from 'react-redux';
import Login from "../auth/Login"
import ActivateList from "./ActivateList";
import { selectAuth } from '../../redux/auth/selectors';

const ActivateMain = () => {
    const { isLoggedIn } = useSelector(selectAuth);


    return (
        isLoggedIn ?
            <>
                <ActivateList />
            </>
            : <Login />
    )
}

export default ActivateMain;