import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { loadStripe } from "@stripe/stripe-js";


import request from '../../service/request';
import { dateFormat, priceFormat } from "../../utils/helper";
import { LoadingButton } from "@mui/lab";
import { STRIPE_KEY } from "../../utils/environment";

const ActivateList = () => {

    const [macAddress, setMacAddress] = useState("");
    const [status, setStatus] = useState(false);
    const [expireDate, setExpireDate] = useState("");
    const [type, setType] = useState(1);
    const [yearPrice, setYearPrice] = useState(0);
    const [lifePrice, setLifePrice] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const result = await request.read({ entity: "config", jsonData: { ids: ["credit_price"] } });
                if (result) {
                    const prices = result?.credit_price;
                    const year_price = prices.find((item) => (item.type === 1))?.price;
                    const life_price = prices.find((item) => (item.type === 2))?.price;
                    setYearPrice(year_price);
                    setLifePrice(life_price);

                }
                const deviceInfo = await request.get({ entity: "profile" });
                if (deviceInfo) {
                    setMacAddress(deviceInfo.mac_address);
                    setStatus(deviceInfo.active_status);
                    setExpireDate(deviceInfo.expire_date);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    const payHandler = async () => {
        try {
            setIsLoading(true);
            const response = await request.post({ entity: 'create-checkout-session', jsonData: { type: type } });
            if (response && response.data) {
                const { id } = response.data;
                const stripe = await loadStripe(STRIPE_KEY);
                const result = await stripe.redirectToCheckout({
                    sessionId: id,
                });

                if (result.error) {
                    console.log(result.error);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error creating payment intent:', error);
            setIsLoading(false);
        }
    }
    return (
        <>
            <div className="container mx-auto px-4 sm:px-6 md:px-8 py-8 pt---50 pb---100">
                <div className="pt-2 pb-1 text-right">
                    <div>Mac address: <span>{macAddress}</span></div>
                    <div>Status: <span>{status ? "Active" : "Deactive"}</span></div>
                    <div>Expiration: <span>{dateFormat(expireDate)}</span></div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
                    <div>
                        <Button component={Link} to="/playlists" fullWidth variant="outlined" color="primary" className="mb-3">
                            PlayLists
                        </Button>
                        <Button component={Link} to="/activate" fullWidth variant="contained" color="primary" className="mb-3">
                            Activate
                        </Button>
                        {
                            status &&

                            <Button component={Link} to="/transfer" fullWidth variant="outlined" color="primary" className="mb-3">
                                Transfer
                            </Button>
                        }
                        <Button component={Link} to="/logout" fullWidth variant="contained" color="error" className="mb-3">
                            <LogoutIcon /> Logout
                        </Button>
                    </div>
                    <div className="col-span-5">
                        <div className="overflow-x-auto">
                            <div className="px-3 py-2 bg-blue-200 rounded-md mb-10">
                                <p>
                                    Disclaimer<br />
                                    We don't provide contents but pure media player. To use our player, you have to upload your own playlist.
                                    If you have no playlist, you can't use the player anymore so in this case no need to pay.
                                    Also we are not allowing activation for the users that don't have own playlist.<br />
                                    As each device has an unique Mac address, a payment is valid for a device only.
                                </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="col-span-1">
                                    <Button size="large" fullWidth variant={type === 1 ? "contained" : "outlined"} color="primary" className="mb-3" onClick={() => setType(1)}>
                                        {type === 1 ? <CheckCircleOutlineTwoToneIcon /> : <RadioButtonUncheckedIcon />}
                                        Price {priceFormat(yearPrice)}$: (One Year)
                                    </Button>
                                </div>
                                <div className="col-span-1">
                                    <Button size="large" fullWidth variant={type === 2 ? "contained" : "outlined"} color="primary" className="mb-3" onClick={() => setType(2)}>
                                        {type === 2 ? <CheckCircleOutlineTwoToneIcon /> : <RadioButtonUncheckedIcon />}
                                        Price {priceFormat(lifePrice)}$: (Lifetime)
                                    </Button>
                                </div>
                            </div>
                            <div className="text-center grid grid-cols-4 pt-4">
                                <div className="col-start-2 col-span-2">
                                    <div>
                                        {/* <Button size="large" fullWidth variant="contained" color="primary" className="mb-3" onClick={payHandler}>Pay</Button> */}
                                        <LoadingButton size="large" fullWidth variant="contained" color="primary" className="mb-3" onClick={payHandler} loading={isLoading}>
                                            Pay
                                        </LoadingButton>
                                    </div>
                                    <div>
                                        <p>
                                            After you make the payment, please reload or restart the application
                                            so that you can see your account has been activated.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivateList;