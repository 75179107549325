import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Aos from "aos";
import { OutlinedInput, FormControl, InputLabel, Typography, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

import { login } from '../../redux/auth/actions';

const Login = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState({
        mac_address: "",
        device_code: "",
    });

    const [errors, setErrors] = useState({
        mac_address: "",
        device_code: "",
    });

    const resetData = () => {
        setDeviceInfo({
            mac_address: "",
            device_code: "",
        });

        setErrors({
            mac_address: "",
            device_code: "",
        });
    }

    const formatMacAddress = (mac) => {
        return mac.replace(/[^a-fA-F0-9]/g, "").match(/.{1,2}/g)?.join(":").substring(0, 17) || "";
    };
    const formatDeviceCode = (code) => {
        return code.replace(/[^0-9]/g, "")?.substring(0, 6) || "";
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === "mac_address" ? formatMacAddress(value) : formatDeviceCode(value);

        setDeviceInfo({
            ...deviceInfo,
            [name]: formattedValue,
        });
        if (name === "mac_address") {
            validateMacAddress(formattedValue);
        } else if (name === "device_code") {
            validateDeviceCode(formattedValue);
        }
    };
    const validateAllFields = () => {
        validateMacAddress(deviceInfo.mac_address);
        validateDeviceCode(deviceInfo.device_code);
    };

    const validateMacAddress = (mac) => {
        const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        const isValid = macRegex.test(mac);
        setErrors((prevErrors) => ({
            ...prevErrors,
            mac_address: isValid ? "" : "Invalid MAC address.",
        }));
    };
    const validateDeviceCode = (device_code) => {
        const phoneRegex = /^\d{6}$/;
        const isValid = phoneRegex.test(device_code);
        setErrors((prevErrors) => ({
            ...prevErrors,
            device_code: isValid ? "" : "Invalid phone number. Must be 6 digits.",
        }));
    }

    const handleSubmit = async () => {
        validateAllFields();

        const hasErrors = Object.values(errors).some(error => error !== "");
        const hasValues = Object.values(deviceInfo).some(item => item === "");
        if (!hasErrors && !hasValues) {
            setIsLoading(true);
            const newUser = { mac_address: deviceInfo.mac_address, device_code: deviceInfo.device_code };
            await dispatch(login({ loginData: newUser }));
            setIsLoading(false);
        }
    };
    return (
        <div className='container h-96 mb-10' data-aos="flip-up">
            <div className='max-w-lg mx-auto relative -mt-20'>
                <Card variant="outlined" className='p-3 mb-2 rounded'>
                    <Typography variant="h6" color="black">Login to add your playlist</Typography>
                    <form>
                        <CardContent className='px-4 pt-3 pb-4'>
                            <div className='mb-3'>
                                <FormControl color='primary' fullWidth >

                                    <TextField
                                        label="Mac Address"
                                        name="mac_address"
                                        value={deviceInfo.mac_address}
                                        onChange={handleChange}
                                        error={!!errors.mac_address}
                                        helperText={errors.mac_address}
                                        fullWidth
                                        required
                                        margin="normal"
                                    />
                                </FormControl>
                            </div>
                            <div className='mb-3'>
                                <FormControl color='primary' fullWidth >
                                    <TextField
                                        label="Device Key"
                                        name="device_code"
                                        value={deviceInfo.device_code}
                                        onChange={handleChange}
                                        error={!!errors.device_code}
                                        helperText={errors.device_code}
                                        fullWidth
                                        required
                                        margin="normal"
                                    />
                                </FormControl>
                            </div>
                            <div>
                                {/* <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                                    Login
                                </Button> */}

                                <LoadingButton
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    loadingPosition="end"
                                    variant="contained"
                                    fullWidth
                                >
                                    <span>Login</span>
                                </LoadingButton>
                            </div>
                        </CardContent>
                    </form>
                </Card>
            </div>
        </div>

    )
}

export default Login
