import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, IconButton, Modal, TextField } from "@mui/material";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import request from '../../service/request';
import { dateFormat } from "../../utils/helper";
import Cookies from 'js-cookie'; 
import { ReplayOutlined } from "@mui/icons-material";
import { selectAuth } from '../../redux/auth/selectors';

const ItemList = () => {
    const { id : deviceId } = useSelector(selectAuth);
    const [macAddress, setMacAddress] = useState("");
    const [status, setStatus] = useState(false);
    const [expireDate, setExpireDate] = useState("");
    const [playList, setPlayList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedPlayList, setSelectedPlayList] = useState();
    const [newPlaylistTitle, setNewPlaylistTitle] = useState("");
    const [newPlaylistUrl, setNewPlaylistUrl] = useState("");

    const [errors, setErrors] = useState({
        title: "",
        url: "",
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const deviceInfo = await request.get({ entity: "profile" });
                if (deviceInfo) {
                    setMacAddress(deviceInfo.mac_address);
                    setStatus(deviceInfo.active_status);
                    setExpireDate(deviceInfo.expire_date);
                }
                loadPlayList();

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    const loadPlayList = async () => {
        try {
            const result = await request.list({ entity: "playlists" });
            if (result) {
                let cookiePlayList = [];
                let updatedPlayList = result;
                const playListString = Cookies.get('playlists');
                if (playListString) {
                    cookiePlayList = JSON.parse(playListString);
                    
                    cookiePlayList.forEach((item) => {
                        if (!updatedPlayList.find((temp) => (temp.title === item.title && temp.url === item.url)) && item.device_id === deviceId) {
                            updatedPlayList.push(item);
                        }
                    });
                }
                
                Cookies.set('playlists', JSON.stringify(updatedPlayList), { expires: 7 });
                setPlayList(updatedPlayList); 
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const openNew = () => {
        setErrors({
            title: "",
            url: "",
        })
        setNewPlaylistTitle("");
        setNewPlaylistUrl("");

        setIsEdit(false);
        setIsModalOpen(true);
    };
    const reuploadHandler = async () => {
        const result = await request.post({ entity: "playlists/reupload", jsonData: {playList} });
        if (result) {
            loadPlayList();
        }
        
    }
    const openEdit = (item) => {
        setErrors({
            title: "",
            url: "",
        })
        setIsEdit(true);
        setSelectedPlayList(item);
        setNewPlaylistTitle(item.title);
        setNewPlaylistUrl(item.url);
        setIsModalOpen(true);
    };
    const openDelete = (item) => {
        setSelectedPlayList(item);
        setDeleteModalOpen(true);
    }
    const closeDeleteConfirmation = () => {
        setDeleteModalOpen(false);
        setSelectedPlayList(null);
    };const handleDelete = async () => {
        if (selectedPlayList) {
            const id = selectedPlayList._id;
            const cookiePlayList = playList.filter(item => item._id !== id);
            Cookies.set('playlists', JSON.stringify(cookiePlayList), { expires: 7 });
            setPlayList(cookiePlayList); 
            const result = await request.delete({ entity: "playlists", id });
        }
        closeDeleteConfirmation();
    };
    
    const handleSubmit = async () => {

        let formIsValid = true;
        const errorsCopy = { ...errors };

        if (!newPlaylistTitle.trim()) {
            errorsCopy.title = "Title is required";
            formIsValid = false;
        } else {
            errorsCopy.title = "";
        }

        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        if (!newPlaylistUrl.trim()) {
            errorsCopy.url = "URL is required";
            formIsValid = false;
        } else if (!urlRegex.test(newPlaylistUrl.trim())) {
            errorsCopy.url = "Invalid URL format";
            formIsValid = false;
        } else {
            errorsCopy.url = "";
        }
        if (!formIsValid) {
            setErrors(errorsCopy);
            return;
        }
        if (isEdit) {
            const id = selectedPlayList._id;

            const newPlaylist = {
                title: newPlaylistTitle,
                url: newPlaylistUrl
            };
            const cookiePlayList = playList.map(item => 
                item._id === id ? { ...item, ...newPlaylist } : item
            );
            Cookies.set('playlists', JSON.stringify(cookiePlayList), { expires: 7 });
            setPlayList(cookiePlayList);

            await request.update({ entity: "playlists", id, jsonData: newPlaylist });
        } else {

            const newPlaylist = {
                title: newPlaylistTitle,
                url: newPlaylistUrl
            };
            const result = await request.create({ entity: "playlists", jsonData: newPlaylist });
            if (result) {
                loadPlayList();
            }

        }

        setIsModalOpen(false);
        setNewPlaylistTitle("");
        setNewPlaylistUrl("");
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 md:px- py-8 pt---50 pb---100">
            <div className="pt-2 pb-1 text-right">
                <div>Mac address: <span>{macAddress}</span></div>
                <div>Status: <span>{status ? "Active" : "Deactive"}</span></div>
                <div>Expiration: <span>{dateFormat(expireDate)}</span></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-6 gap-6">
                <div>
                    <Button component={Link} to="/playlists" fullWidth variant="contained" color="primary" className="mb-3">
                        PlayLists
                    </Button>
                    <Button component={Link} to="/activate" fullWidth variant="outlined" color="primary" className="mb-3">
                        Activate
                    </Button>
                    {
                        status &&

                        <Button component={Link} to="/transfer" fullWidth variant="outlined" color="primary" className="mb-3">
                            Transfer
                        </Button>
                    }
                    <Button component={Link} to="/logout" fullWidth variant="contained" color="error" className="mb-3">
                        <LogoutIcon /> Logout
                    </Button>
                </div>
                <div className="col-span-5">
                    <Button size="small" variant="outlined" color="primary" className="mb-3" onClick={openNew}>
                        <AddIcon /> Add Playlist
                    </Button>&nbsp;
                    <Button size="small" variant="outlined" color="primary" className="mb-3" onClick={reuploadHandler}>
                        <ReplayOutlined /> Reupload
                    </Button>
                    <div >
                        <div className="overflow-x-auto">
                            <table className="w-full text-sm text-left">
                                <thead className="text-xs uppercase bg-blue-700 text-white">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">Name</th>
                                        <th scope="col" className="px-6 py-3">URL</th>
                                        <th scope="col" className="px-6 py-3"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {playList.map((item, index) => (
                                        <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                            <td className="px-2 py-1" style={{ width: '20%' }}>{item.title}</td>
                                            <td className="px-2 py-1" style={{ width: '70%' }}>{item.url}</td>
                                            <td className="px-2 py-1 text-right" style={{ width: '10%' }}>
                                                <IconButton color="primary" aria-label="edit" onClick={() => openEdit(item)}>
                                                    <CreateRoundedIcon />
                                                </IconButton>
                                                <IconButton color="error" aria-label="delete" onClick={() => openDelete(item)}>
                                                    <DeleteOutlineRoundedIcon />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Playlist Modal */}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-lg font-semibold mb-4">{isEdit ? "Edit Playlist" : "Add Playlist"}</h2>
                    <TextField
                        label="Title"
                        fullWidth
                        margin="normal"
                        required
                        value={newPlaylistTitle}
                        error={!!errors.title}
                        helperText={errors.title}
                        onChange={(e) => setNewPlaylistTitle(e.target.value)}
                    />
                    <TextField
                        label="URL"
                        fullWidth
                        margin="normal"
                        required
                        value={newPlaylistUrl}
                        error={!!errors.url}
                        helperText={errors.url}
                        onChange={(e) => setNewPlaylistUrl(e.target.value)}
                    />
                    <div className="my-2 text-right" >
                        <Button onClick={handleSubmit} variant="contained" color="primary" size="large">{isEdit ? "Edit" : "Add"}</Button>
                        &nbsp;
                        <Button onClick={() => setIsModalOpen(false)} variant="contained" color="error" size="large">Cancel</Button>
                    </div>

                </div>
            </Modal>
            <Modal open={deleteModalOpen} onClose={closeDeleteConfirmation}>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg">
                    <h2 className="text-lg font-semibold mb-4">Confirm Delete</h2>
                    <p>Are you sure you want to delete this playlist?</p>
                    <div className="flex justify-end mt-4">
                        <Button onClick={closeDeleteConfirmation} color="primary">Cancel</Button>
                        <Button onClick={handleDelete} color="error">Delete</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ItemList;
